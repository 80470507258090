
import Image from "gatsby-image";
import React from "react";

import {gsap} from "gsap";
import CustomEase from "gsap/CustomEase"
//import { ScrollTrigger } from "gsap/ScrollTrigger"

const imgAnimation = (name) => {
    //console.log('done');
    // gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(CustomEase);
    const image = document.querySelector('.' + name + ' > picture > img');
    if (image) {
            gsap
                .fromTo(image,
                    {
                        opacity: 0
                    },
                    {
                        opacity: 1,
                        duration: .4,
                        ease: CustomEase.create("custom", "M0,0 C0,0 0.03386,0.1654 0.06282,0.26814 0.0873,0.35495 0.10382,0.40627 0.13772,0.48848 0.16554,0.55594 0.18587,0.59821 0.22396,0.65889 0.25792,0.71299 0.28469,0.74834 0.32888,0.793 0.36911,0.83366 0.40199,0.85936 0.45107,0.88843 0.502,0.9186 0.54119,0.93506 0.59853,0.95274 0.66304,0.97262 0.70743,0.98051 0.77593,0.98857 0.86085,0.99857 1,1 1,1")
                    });
    }
}

const ImageWithAnimation = ({
    src,
    alt = ""
}) => {
    let className = 'iwa' + (Math.random() + 1).toString(36).substring(7);
    return (
        <Image
            fluid={src}
            className={className}
            fadeIn={false}
            onLoad={() => {imgAnimation(className)}}
            alt={alt}
        />
    )
}

export default ImageWithAnimation;
