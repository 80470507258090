import React, { useEffect } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageWithAnimation from "../components/ImageWithAnimation";
import { textAppearAnimationAll } from "../components/textAppearAnimation"
import TextRepeater from "../components/TextRepeater"

const NewsPostTemplate = ({ data: { previous, next, news } }) => {
  const featuredImage = {
    fluid: news.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: news.featuredImage?.node?.alt || ``,
  }

  useEffect(() => {
    textAppearAnimationAll("h1");
    textAppearAnimationAll("h2");
    textAppearAnimationAll("h3");
  }, []);

  return (
    <Layout page={"news-post"}>

      <Seo title={news.seoSettings.title} description={news.seoSettings.description} slug={"blog/"+news.slug} extendedTitle="false" />

      <div className={"padding-bottom-96"}>
        <div className={"feature-image wp-block-image"}>
          {featuredImage?.fluid && (
            <>
                <ImageWithAnimation src={featuredImage.fluid}
                                    alt={featuredImage.alt}/>
              <TextRepeater text={news.date} repeat={30} />
            </>
          )}
          <div className={"container"}>
            <h1>{parse(news.title)}</h1>
          </div>
        </div>
      </div>

      <div className={"container"}>
      <article
        className="blog-post padding-bottom-96"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className={"section-overview"}>
          {!!news.content && (
            <section itemProp="articleBody">{parse(news.content)}</section>
          )}
        </div>
      </article>
      </div>

    </Layout>
  )
}

export default NewsPostTemplate

export const pageQuery = graphql`
    query NewsPostById(
        # these variables are passed in via createPage.pageContext in gatsby-node.js
        $id: String!
    ) {
        # selecting the current post by id
        news: wpNews(id: { eq: $id }) {
            id
            content
            title
            date(formatString: "DD MMMM YYYY")
            slug
                  
            seoSettings {
              title
              description
              fieldGroupName
            }

            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1000, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
      
        }
    }
`
